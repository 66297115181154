.container {
  width: 100%;

  .maps {
    width: 100%;
    // background-color: #F5F6FA;
  }

  .mapsInfo {
    display: flex;
    align-items: center;
  }

  .mapLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 291px;
  }

  .locationInfo {
    width: 100%;
    height: 32px; //styleName: Base/14 Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: left;
    margin-left: 4px;
    color: #1a1a1a;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
