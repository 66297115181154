@mixin txtSingleR {
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin txtline($lineNum: 2) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lineNum;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
