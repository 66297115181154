@import "@/app/csr-src/pages/order/common/common.scss";
.select_wrap {
  background-color: #f7f8fc;
  border-radius: 4px;
  .placeholder_style {
    color: #c2c4cc;
  }
  :global {
    .MuiSelect-outlined.MuiSelect-select {
      padding: 9.5px 12px;
      color: #1a1a1a;
      font-size: 14px;
      // min-height: 23px;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
    .MuiFormControl-root {
      .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: transparent;
        }
      }
      .MuiOutlinedInput-root:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: transparent;
        }
      }
    }
    .MuiSelect-iconOutlined {
      top: calc(50% - 10px);
    }
  }
}
.item_style {
  color: #1a1a1a;
  width: 470px;
  @include txtSingleR;
}
