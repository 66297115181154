.return_info_wrap {
  padding: 0 12px;
  background-color: #fff;
  border-radius: 4px;
  border-radius: 4px;
  margin-top: 16px;

  .return_info_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;

    .return_info_title {
      font-weight: 700;
      font-size: 18px;
      color: var(--sys-Text-Primary);
    }
  }

  .copy_btn {
    color: rgba(24, 105, 245, 1);
    cursor: pointer;
    padding-left: 8px;
  }

  .copy_btn:hover {
    color: #990915;
  }

  .return_reason_title {
    padding: 8px 0;
    font-weight: 700;
    font-size: 18px;
    color: var(--sys-Text-Primary);
  }
  .return_reason_contents {
    padding-bottom: 8px;
    .return_reason_content_title {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: var(--sys-Text-Secondary);
      overflow: hidden;
    }
    .return_reason_content_text {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: var(--sys-Text-Secondary);
      overflow: hidden;
      word-break: break-word;
    }
    .mediaZone {
      display: flex;
      gap: 12px;
      margin: 12px 0;
      flex-wrap: wrap;

      img {
        width: 140px;
        height: 140px;
        object-fit: cover;
        border-radius: 4px;
        cursor: pointer;
      }

      .videoBox {
        width: 140px;
        height: 140px;
        object-fit: cover;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;
      }
    }
  }
}
