@import "@/app/csr-src/pages/order/common/common.scss";
.selectWrap {
  height: 100%;
}
.searchBarWrap {
  margin-bottom: 10px;
}
.searchListWrap {
  height: calc(100% - 46px);
  .locationListWrap {
    height: calc(100% - 291px);
    overflow-y: auto;
    padding-top: 10px;
  }
  .searchResultItem {
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    padding: 8px 16px;
    border-bottom: 1px solid #e0e1e5;
  }
  .searchResultItem:last-child {
    margin-bottom: 0;
  }
  .emptyWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .loadingWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .loadingText {
      font-size: 14px;
      color: #c2c4cc;
      margin-top: 14px;
    }
  }
}
.inputEndAdornmentStyle {
  display: flex;
  align-items: center;
  padding: 4px 10px 4px 4px;
  cursor: pointer;
}

.intro_wrap {
  margin-top: 4px;
  display: flex;
  align-items: center;
  color: #1a1a1a;
  .time {
    margin-right: 16px;
  }
}
.locationItemWrap {
  display: flex;
  align-items: flex-start;
  flex: 1;
  overflow: hidden;
  .locationMain {
    flex: 1;
    margin-left: 16px;
    color: #1a1a1a;
    overflow: hidden;
    .locationName {
      font-weight: 700;
      line-height: 20px;
    }
    .locationDis {
      margin-left: 16px;
    }
    .locationDesc {
      line-height: 24px;
      @include txtSingleR;
      margin-top: 4px;
      color: #505259;
    }
    .businessHoursTitle {
      line-height: 24px;
      color: #888b94;
      margin-top: 4px;
    }
    .businessHoursItem {
      width: 375px;
      height: 20px;
      margin-top: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #888b94;
    }
  }
}

.moreIco {
  color: #888b94;
  width: 10px;
  margin-left: 16px;
}
.retractIco {
  transform: rotate(180deg);
}

.searchResultItem:hover {
  .moreIco {
    color: #990915;
  }
}
