.order_detail_wrap {
  background-color: rgba(245, 246, 250, 1);
  color: rgba(26, 26, 26, 1);
  padding-bottom: 32px;
  .detail_inner_wrap {
    width: 1264px;
    margin: 0 auto;
    font-size: 14px;
    .order_content {
      margin-bottom: 32px;
    }
  }
}
.emptyBlock {
  height: 100vh;
}
