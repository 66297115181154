.apply_success_modal_title {
  display: flex;
  align-items: center;
  font-size: 18px;
  .apply_success_icon {
    margin-right: 16px;
  }
}
.apply_success_content {
  color: #1b1b1b;
  margin: 8px 0 24px;
}
.oper_wrap {
  display: flex;
  justify-content: flex-end;
}
