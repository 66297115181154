@import "../../common/common.scss";
.wrap {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
  .product_title {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
  .product_outer_wrap {
    padding-top: 16px;
  }

  .product_wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    cursor: pointer;
    .left_product {
      .product_info {
        display: flex;
        .product_img {
          width: 60px;
          height: 60px;
          cursor: pointer;
        }
        .product_main_info {
          margin-left: 16px;
          width: 905px;
        }
        .productName {
          .tagsWrap {
            float: left;
            display: flex;
            align-items: center;
            position: relative;
            top: 2px;
          }
          .product_name_cont {
            font-size: 16px;
            line-height: 20px;
            @include txtSingleR;
          }
        }
        .product_name:hover {
          color: #990915;
        }
        .product_desc {
          font-size: 14px;
          line-height: 18px;
          color: rgba(136, 139, 148, 1);
          margin-top: 4px;
          @include txtline(2);
        }
      }
    }
    .right_product {
      display: flex;
      .price_wrap {
        display: flex;
        vertical-align: bottom;
        height: 22px;
        align-items: flex-end;
        width: 150px;
      }
      .real_price {
        font-family: JDZhengHT-EN;
        font-size: 12px;
        line-height: 18px;
        .main_size {
          font-size: 18px;
          line-height: 18px;
        }
      }
      .origin_price {
        margin-left: 4px;
      }
      .product_num {
        font-family: JDZhengHT-EN;
        font-size: 12px;
        height: 18px;
        line-height: 18px;
        color: rgba(136, 139, 148, 1);
        width: 51px;
        text-align: right;
        margin-left: 40px;
        margin-top: 3px;
      }
    }
  }
  .product_wrap:last-child {
    margin-bottom: 0;
  }
  .product_special_wrap {
    .left_product {
      .product_info {
        .product_main_info {
          width: 665px;
        }
      }
    }
    .right_product {
      .product_num {
        margin-left: 30px;
      }
    }
    .btn_group {
      display: flex;
      justify-content: end;
      width: 190px;
      margin-left: 40px;
    }
  }

  .add_success_wrap {
    display: flex;
    align-items: center;
    .success_ico {
      width: 20px;
      margin-right: 8px;
    }
  }
  :global {
    .MuiPaper-root {
      background-color: #fff;
      color: rgba(26, 26, 26, 1);
      min-width: 200px;
    }
    .MuiSnackbar-root {
      top: 20%;
    }
  }
}
