@import "../../common/common.scss";
.refund_product_modal {
  .return_wrap {
    .select_all_wrap {
      cursor: pointer;
      height: 48px;
      display: flex;
      align-items: center;
      padding: 0 16px;
      .select_num {
        color: #888b94;
        margin-left: 8px;
      }
    }
    .ware_list_wrap {
      // max-height: calc(100vh - 341px);
      min-height: 119px;
      overflow-y: auto;
      .ware_item_wrap {
        display: flex;
        padding: 8px 16px;
        .product_img {
          width: 100px;
          height: 100px;
          cursor: pointer;
          margin-right: 16px;
          margin-left: 4px;
        }
        .product_cont {
          width: 360px;
          .productNameWrap {
            overflow: hidden;
          }
          .tagsWrap {
            float: left;
            display: flex;
            align-items: center;
            position: relative;
            top: 4px;
          }
          .product_name_cont {
            font-size: 18px;
            line-height: 24px;
            @include txtSingleR;
          }
          .product_desc_wrap {
            display: flex;
            justify-content: space-between;
            margin-top: 6px;
            .product_property,
            .product_num {
              font-size: 14px;
              line-height: 18px;
              color: #888b94;
            }
            .product_property {
              max-width: 235px;
              @include txtSingleR;
              margin-bottom: 6px;
            }
            .price_wrap {
              text-align: right;
              align-self: end;
            }
            .origin_price {
              line-height: 1;
            }
          }
        }
      }
    }
    .ware_list_wrap_large {
      height: 532px;
    }

    .counter_wrap {
      width: 72px;
    }
  }
  .loading_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 106px 0;
    .loading_text {
      font-size: 14px;
      color: #c2c4cc;
      margin-top: 14px;
    }
  }
  .oper_wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
  }
  :global {
    .MuiDialog-paper {
      width: 592px;
    }
    // .MuiDialogContent-root {
    //   padding: 0 16px 24px;
    // }
  }
}
