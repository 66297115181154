.filterArea {
  width: 240px;
  border: 1px solid #e0e1e5;
  border-radius: 8px;
  padding: 12px;
  align-self: flex-start;
  position: sticky;
  top: 32px;
  max-height: calc(100vh - 32px - 72px);
  overflow: auto;
  .hideBorder {
    border-bottom: none !important;
  }
  .resultCount {
    font-size: 14px;
    color: #bfbfbf;
    // margin-bottom: 14px;
    b {
      color: #1a1a1a;
    }
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 限制为两行 */
    -webkit-box-orient: vertical; /* 必须设置为垂直方向 */
    overflow: hidden; /* 隐藏超出的内容 */
    text-overflow: ellipsis;
  }
  .header {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    padding-bottom: 12px;
    span {
      color: #1a1a1a;
      font-weight: 700;
    }
    .reset {
      cursor: pointer;
      color: #1869f5;
      font-weight: normal;
    }
  }
  h4 {
    font-size: 16px;
    font-weight: 700;
    padding-top: 16px;
    padding-bottom: 12px;
    max-width: 240px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .priceTool {
    display: flex;
    flex-direction: row;
    padding-top: 28px;
    border-bottom: 1.5px solid #f0f1f2;
    padding-bottom: 12px;
    padding-left: 10px;
  }
  .autoFoldWrapper {
    max-height: 120px;
    overflow: hidden;
    &.unfold {
      max-height: none;
    }
  }
  .tagWrapper {
    border-bottom: 1.5px solid #f0f1f2;
    padding-bottom: 10px;
  }
  .foldBtn {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: inline-block;
    cursor: pointer;
    svg {
      margin-top: -10px;
      display: inline-block;
      &.svgExpand {
        margin-top: 2px;
        transform: rotateX(180deg);
      }
    }
  }
  .tags {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .tag {
      max-width: 214px;
      overflow: hidden;
      text-overflow: ellipsis;
      background: #f7f8fc;
      padding: 5px 12px;
      font-size: 14px;
      border-radius: 4px;
      color: #505259;
      margin-right: 8px;
      margin-bottom: 8px;
      cursor: pointer;
      border: 0.5px solid #f7f8fc;
      &.active {
        border: 0.5px solid #ff0f23;
        background-color: #fff3f4;
        color: #ff0f23;
      }
      &:hover {
        color: #ff3f4f;
      }
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}
