@import "../../common/common.scss";
.refund_modal {
  .refund_item_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 18px;
    margin-top: 16px;
    .refund_item_title_left {
      font-size: 14px;
      font-weight: 700;
    }
    .require_style {
      font-weight: 400;
      color: #cc0c1c;
      margin-right: 4px;
    }
  }
  .refund_item_title:first-child {
    margin-top: 0;
  }
  .ruleContainer {
    margin: 0 -24px;
  }
  .upload_wrap,
  .reason_wrap {
    margin-top: 8px;
  }
  .detail_wrap {
    margin-top: 16px;
    position: relative;
  }
  .methodWrap {
    display: flex;
    justify-content: space-between;
  }
  .methodItem {
    width: 264px;
    border: 1px solid #e0e1e5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 62px;
    padding: 0 12px;
    cursor: pointer;
    .methodItemTitle {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
    }
    .methodItemDesc {
      font-size: 12px;
      line-height: 16px;
      color: #505259;
      margin-top: 4px;
    }
  }
  .methodActiveItem {
    border: 2px solid #cc0c1c;
  }
  .dropOffWrap {
    background: #f5f6fa;
    border-radius: 8px;
    padding: 12px 16px;
    margin-top: 16px;
    cursor: pointer;
    .dropOffTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 18px;
      font-size: 14px;
      line-height: 18px;
      color: #505259;
    }

    .dropOffTitle:hover {
      .entryWrap,
      .entryIco {
        color: #990915;
      }
    }
    .entryWrap {
      display: flex;
      align-items: center;
    }
    .entryIco {
      color: #888b94;
      margin-left: 2px;
      transform: rotate(-90deg);
    }
    .dropOffContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 24px;
      margin-top: 8px;
      font-size: 14px;
      line-height: 18px;
      color: #505259;
    }
    .dropOffContentMain {
      display: flex;
      align-items: center;
      .recommendStyle {
        margin-right: 4px;
      }
      .locationIcoStyle {
        color: #505259;
        margin-right: 4px;
      }
      .locationName {
        color: #1a1a1a;
        font-weight: 700;
      }
    }
  }
  .refund_wrap {
    margin-top: 20px;
    .refund_item_title,
    .refund_item_sub_title {
      height: 32px;
      margin-bottom: 8px;
      margin-top: 0;
    }
    .tip_ico {
      cursor: pointer;
    }
    .refund_item_sub_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;
      font-size: 14px;
    }
    .coupon_desc {
      font-size: 14px;
      color: #888b94;
      line-height: 18px;
      margin: 8px 0;
    }
  }
  .oper_wrap {
    display: flex;
    justify-content: flex-end;
    margin-right: 8px;
    padding-top: 24px;
  }
  .loading_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 106px 0;
    .loading_text {
      font-size: 14px;
      color: #c2c4cc;
      margin-top: 14px;
    }
  }
  :global {
    .MuiDialog-paper {
      width: 592px;
    }
    .MuiDialogContent-root {
      padding: 0 24px 24px;
    }
  }
}
.input_tip {
  position: absolute;
  bottom: 8px;
  right: 8px;
  font-size: 12px;
  line-height: 16px;
  color: #c2c4cc;
}
.uploadTips {
  color: #888b94;
  margin-top: 4px;
  font-size: 12px;
}

.detailTextfield {
  height: 80px;
  :global {
    .MuiInputBase-root {
      height: 100%; /* 确保输入框填满整个高度 */
      align-items: start;
      padding: 12px;
      background-color: #f7f8fc;
      font-size: 14px;
      line-height: 18px;
      color: #1a1a1a;
      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: transparent; /* 聚焦时去掉边框颜色 */
      }
    }
    .MuiInputBase-root:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: transparent; /* 去掉边框颜色 */
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: transparent; /* 去掉边框颜色 */
    }
    textarea::placeholder {
      color: #c2c4cc; /* 设置 placeholder 颜色 */
      opacity: 1; /* 确保不透明度为 1 */
    }
  }
}
