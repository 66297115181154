.modal_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  // width: 480px;
  width: 520px;
  border-radius: 12px;
  padding: 20px 24px 24px;
  color: rgba(26, 26, 26, 1);
  .modal_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    .close_ico {
      cursor: pointer;
      width: 20px;
      height: 20px;
      padding: 0 4px;
      color: rgba(136, 139, 148, 1);
    }
  }
  .main_cont {
    font-size: 16px;
    margin: 12px 0 32px;
    min-height: 432px;
  }
  .oper_wrap {
    display: flex;
    justify-content: flex-end;
  }
  :global {
    .MuiSvgIcon-root {
      width: 16px;
      height: 16px;
    }
    .MuiRadio-sizeSmall {
      padding: 16px 8px 16px 0;
    }
    .MuiFormControlLabel-root {
      margin-left: 0;
    }
    .MuiFormControlLabel-root:hover {
      .MuiRadio-root {
        background-color: transparent;
        color: #990915;
      }
    }
  }
}
