.record_list_wrap {
  .detail_desc_wrap {
    display: flex;
    padding: 16px;
    border-radius: 8px;
    background: rgba(247, 248, 252, 1);
    margin-top: 16px;
  }
  .detail_desc_wrap_separate {
    justify-content: space-between;
  }
  .after_sales_wrap {
    display: flex;
    align-items: center;
    i {
      width: 16px;
      height: 18px;
      background: url("../../../order/assets/cancel_ico.png") no-repeat;
      background-image: url("../../../order/assets/refund_ico.png");
      background-size: 100%;
      margin-right: 8px;
    }
    .after_sales_title {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: rgba(0, 0, 0, 1);
      margin-right: 8px;
    }
  }
  .after_sales_detail {
    font-size: 14px;
    line-height: 18px;
    color: rgba(80, 82, 89, 1);
  }
  .outer_common_layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .after_sales_detail {
      min-height: 18px;
    }
    // .common_afs_wrap {
    //   height: 178px;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: center;
    // }
  }
  .common_layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .small_common_layout {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top: 4px;
    margin-right: -12px;
  }
  .common_afs_wrap {
    padding: 16px;
    border-radius: 8px;
    background: rgba(247, 248, 252, 1);
    margin-top: 16px;
    width: 610px;
  }
  // 申请单基本信息
  .base_info_item {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .copy_btn {
    color: rgba(24, 105, 245, 1);
    cursor: pointer;
    padding-left: 8px;
  }
  .copy_btn:hover {
    color: #990915;
  }
  .btn_group {
    display: flex;
    align-items: center;
  }

  .view_more {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    margin-top: 16px;
    font-size: 14px;
    cursor: pointer;
    .more_ico {
      color: rgba(26, 26, 26, 1);
      width: 10px;
      margin-left: 4px;
    }
    .retract_ico {
      transform: rotate(180deg);
    }
  }
  .view_more:hover {
    color: #990915;
    .more_ico {
      color: #990915;
    }
  }
}
.orderPriceStyle {
  font-family: JDZhengHT-EN;
}
