@import "../../common/common.scss";
.wrap {
  font-size: 14px;
  .order_item_wrap {
    margin-bottom: 16px;
    border: 1px solid rgba(240, 241, 242, 1);
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    .order_item_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      background-color: rgba(245, 246, 250, 1);
      padding: 0 16px;
      border-bottom: 1px solid rgba(240, 241, 242, 1);

      .center_style {
        display: flex;
        align-items: center;
      }
      .title_label_style {
        font-size: 14px;
        line-height: 18px;
        color: rgba(136, 139, 148, 1);
      }
      .order_info_wrap {
        margin-left: 24px;
        font-size: 14px;
        line-height: 18px;
      }
      .left_wrap {
        .order_status {
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
        }
        .title_label_style {
          margin-right: 8px;
        }
      }
      .right_wrap {
        .title_label_style {
          margin-right: 4px;
        }
        .to_detail {
          cursor: pointer;
          .entry_ico {
            color: rgba(26, 26, 26, 1);
            width: 10px;
            transform: rotate(-90deg);
            margin-left: 8px;
          }
        }
        .to_detail:hover {
          color: #990915;
          .entry_ico {
            color: #990915;
          }
        }
      }
    }

    .ageTips {
      height: 48px;
      border-bottom: 1px solid #f0f1f2;
      display: flex;
      align-items: center;
      padding: 0 16px;
      .ageTipsContent {
        margin: 0 4px;
        font-weight: 700;
      }
      .entry_ico {
        margin-left: 4px;
        color: #888b94;
        width: 10px;
        transform: rotate(-90deg);
      }
    }

    .product_wrap {
      display: flex;
      padding: 12px 16px;
      .left_product {
        margin-right: 120px;
        .product_info {
          display: flex;
          .product_img {
            width: 60px;
            height: 60px;
            cursor: pointer;
          }
          .product_main_info {
            margin-left: 16px;
            width: 531px;
          }
          .productName {
            .tagsWrap {
              float: left;
              display: flex;
              align-items: center;
              position: relative;
              top: 2px;
            }
            .product_name_cont {
              @include txtSingleR;
              font-size: 16px;
              line-height: 20px;
            }
          }
          .product_desc {
            font-size: 14px;
            line-height: 18px;
            color: rgba(136, 139, 148, 1);
            margin-top: 4px;
            @include txtline(2);
          }
        }
      }
      .right_product {
        display: flex;
        justify-content: space-between;
        flex: 1;
        .price_wrap {
          max-width: 120px;
          position: relative;
          top: -3px;
        }
        .real_price {
          font-family: JDZhengHT-EN;
          font-size: 12px;
          line-height: 18px;
          @include txtSingleR;
          .main_size {
            font-size: 18px;
            line-height: 18px;
          }
        }
        .origin_price {
          // font-family: JDZhengHT-EN;
          // font-size: 12px;
          // line-height: 16px;
          // color: rgba(136, 139, 148, 1);
          // text-decoration: line-through;
          margin-top: 3px;
          @include txtSingleR;
        }
        .product_num {
          font-family: JDZhengHT-EN;
          font-size: 12px;
          line-height: 16px;
          color: rgba(136, 139, 148, 1);
          width: 32px;
          margin-top: 3px;
        }
      }
    }

    .view_more {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 34px;
      border-top: 1px solid rgba(240, 241, 242, 1);
      font-size: 14px;
      cursor: pointer;
      .more_ico {
        color: rgba(26, 26, 26, 1);
        width: 10px;
        margin-left: 4px;
      }
      .retract_ico {
        transform: rotate(180deg);
      }
    }
    .view_more:hover {
      color: #990915;
      .more_ico {
        color: #990915;
      }
    }

    .operate_wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      padding: 0 16px;
      border-top: 1px solid rgba(240, 241, 242, 1);
      .operateWrapLeft {
        max-width: 780px;
      }
    }
    .logisticsWrap {
      overflow: hidden;
      display: flex;
      .mainNodeStyle {
        font-weight: 700;
        margin-right: 8px;
      }
      .subNodeStyle {
        flex: 1;
        @include txtSingleR;
      }
    }
    .btn_group_wrap {
      display: flex;
      align-items: center;
    }
    .count_down_wrap {
      display: flex;
      align-items: center;
    }
    .sub_title_style {
      margin-left: 8px;
    }
  }
  .order_item_wrap:last-child {
    margin-bottom: 0;
  }
  .pagination_wrap {
    display: flex;
    justify-content: end;
  }
}
