.cancel_success_wrap {
  color: rgba(26, 26, 26, 1);
  padding-bottom: 32px;
  .detail_inner_wrap {
    width: 1264px;
    margin: 0 auto;
    font-size: 14px;
    .cancel_success_content {
      margin: 32px 0;
      background-color: #ffffff;
      border-radius: 8px;
      padding: 16px;
      text-align: center;
      .title_style {
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 8px;
        .title_ico {
          width: 32px;
          height: 32px;
          margin-right: 8px;
        }
      }
      .desc_style {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 20px;
      }
      .btn_group {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
