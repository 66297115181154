.view_image {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  // background-color: rgba(0, 0, 0, 0.8);
  background-color: #1a1a1a;
  opacity: 1;
  z-index: 9998;
  transition: all 0.3s ease-in-out;
  overflow-y: hidden;
  .mack {
    width: 70vw;
    height: 90vh;
    position: absolute;
    z-index: 9998;
    top: 50%;
    left: 50%;
    transform: translate(-53%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // overflow-y: hidden;
    .imgVideoBox {
      width: 100%;
      height: 80%;
    }
    .videoPayBig {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      transition: opacity 0.3s ease-in-out;
      opacity: 1;
      z-index: 3;
    }
    .videoPayBigShow {
      opacity: 0;
      z-index: 1;
    }
    .videoPlayer_mask_wrap {
      width: 100%;
      height: 100%;
      background-color: #1a1a1a;
      position: relative;
      .videoPlayer_mask_wrap_video {
        width: 100%;
        height: calc(100% - 40px);
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .consoleWrap {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 8px 0;
        .consoleIcon {
          cursor: pointer;
          width: 32px;
          height: 32px;
          background-color: #505259;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background-color: #888b94;
          }
        }
        .volumeIcon {
          position: relative;
          z-index: 19;
          .volumeSliderWrap{
            position: absolute;
            bottom: 102%;
            left: 50%;
            z-index: 19;
            transform: translateX(-50%);
            width: 32px;
            height: 110px;
          }
          .volumeSlider {
            position: absolute;
            left: 50%;
            bottom: 5px;
            z-index: 19;
            transform: translateX(-50%);
            width: 32px;
            height: 100px;
            border-radius: 16px;
            // background: rgba(255, 255, 255, 0.3);
            background: rgba(0, 0, 0, 0.5);
            padding: 16px 0 8px 0;
            // opacity: 0;
            transition: opacity 0.3s ease-in-out;
            user-select: none; /* 禁止文本选择 */
          }
          .volumeLevel {
            position: absolute;
            bottom: 8px;
            left: 50%;
            transform: translateX(-50%);
            background: #fff;
            width: 10px;
            border-radius: 16px;
            user-select: none; /* 禁止文本选择 */
          }
        }
        .consoleWrapCenter {
          display: flex;
          justify-content: center;
          align-items: center;
          .duration,
          .currentTime {
            color: #fff;
            font-size: 14px;
            line-height: 20px;
            margin: 0 16px;
          }
          .progressBarWrap {
            position: relative;
            width: 650px;
            display: flex;
            justify-content: center;
            align-items: center;
            .progressBar {
              width: 100%;
              height: 10px;
              background: rgba(255, 255, 255, 0.3);
              cursor: pointer;
              position: relative;
              border-radius: 4px;
              overflow: hidden;
            }
            .progressFilled {
              height: 100%;
              background-color: #fff;
              position: absolute;
              border-radius: 4px;
            }
          }
        }
        .consoleWrap_left {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
        }
      }
      .textWrap {
        width: 80%;
        color: #fff;
        .textWrap_title {
          padding: 8px 0;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          line-height: 20px;
          padding: 4px 0;
        }
        .textWrap_content {
          font-size: 14px;
          line-height: 20px;
        }
      }
      .closeIcon {
        position: absolute;
        top: 5%;
        right: 5%;
        cursor: pointer;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .header {
      width: 40vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      color: #fff;
      font-size: 16px;
    }
    .content {
      width: 40vw;
      color: #fff;
      white-space: pre-wrap;
      word-break: break-all;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .children {
      color: #fff;
    }
  }
  .pageLeft {
    width: 40px;
    height: 40px;
    position: fixed;
    z-index: 9999;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #505259;
    &:hover {
      background-color: #888b94;
    }
    left: 3.6%;
    transform: translateX(-50%);
  }
  .pageRight {
    width: 40px;
    height: 40px;
    position: fixed;
    z-index: 9999;
    top: 50%;
    border-radius: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #505259;
    &:hover {
      background-color: #888b94;
    }
    right: 3.6%;
    transform: translateX(-50%);
  }
  .closeIcon {
    position: absolute;
    z-index: 9999;
    top: 5%;
    right: 5%;
    cursor: pointer;
  }
}
.view_image_wrapper {
  opacity: 1;
}
.reportFormWrapper {
  .reasonLabel {
    font-size: 14px;
    font-weight: 700;
    position: relative;
    padding-left: 10px;
    &:before {
      content: "*";
      position: absolute;
      left: 0;
      top: 2px;
      color: rgba(255, 15, 35, 1);
      font-weight: 400;
      font-size: 14px;
    }
  }
}
.reportReasonWrapper {
  font-size: 14px;
  margin-bottom: 20px;
}
.deleteText {
  font-size: 14px;
  margin: 30px 0 20px 0;
}
.dialogFooterAction {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 12px;
  padding: 12px 0;
}
.dialog-footer-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  padding: 24px;
}
.commentTextarea {
  position: relative;
  margin-top: 20px;
  .title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  textarea {
    display: block;
    width: 100%;
    border-radius: 4px;
    background-color: rgba(247, 248, 252, 1);
    font-size: 16px;
    padding: 12px;
    line-height: 24px;
    height: 150px;
    resize: none;
  }
  .num {
    position: absolute;
    bottom: 3px;
    right: 25px;
    font-size: 12px;
    color: rgba(194, 196, 204, 1);
  }
}
