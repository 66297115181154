.step_wrap {
  .step_label {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #1a1a1a;
  }
  .error_step_label {
    color: #cc0c1c;
  }

  :global {
    .MuiStepIcon-root {
      color: #fff;
      border: 1px solid #f0f1f2;
      border-radius: 50%;
    }
    .MuiStepIcon-text {
      fill: #505259;
      font-size: 14px;
    }
    .MuiStepConnector-lineHorizontal {
      border-color: rgba(0, 0, 0, 0.06);
    }
    .Mui-active,
    .Mui-completed {
      .MuiStepConnector-lineHorizontal {
        border-color: #cc0c1c;
      }
      .MuiStepIcon-text {
        fill: #fff;
      }
    }
    .Mui-active {
      color: #cc0c1c;
    }
  }
}
