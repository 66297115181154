.wrap {
  display: flex;
  margin-top: 16px;
  .left_info,
  .right_info {
    flex: 1;
    background-color: #fff;
    border-radius: 8px;
    padding: 4px 16px;
  }
  .left_info {
    margin-right: 12px;
  }
  .info_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 34px;
    font-size: 14px;
    .copy_btn {
      color: rgba(24, 105, 245, 1);
      cursor: pointer;
      padding-left: 3px;
    }
    .copy_btn:hover {
      color: #990915;
    }
  }
  .info_item_divide {
    border-bottom: 1px solid #f0f1f2;
  }
  .info_item_label {
    color: rgba(136, 139, 148, 1);
  }
  .info_item_value {
    max-width: 489px;
  }
  .info_item_special {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin: 8px 0;
    .address_item {
      line-height: 18px;
      text-align: right;
      flex: 1;
      margin-left: 10px;
      max-width: 472px;
      .address_item_inner {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .address_nick_name {
        font-weight: 700;
        max-width: 345px;
        color: #1a1a1a;
      }
      .address_postcode {
        margin-left: 4px;
        max-width: 125px;
        color: #1a1a1a;
      }
      .address_detail {
        margin-top: 4px;
        color: #1a1a1a;
        text-align: right;
        word-break: break-word;
      }
    }
    .expand_wrap {
      cursor: pointer;
      flex: 1;
      .more_ico {
        color: rgba(26, 26, 26, 1);
        width: 20px;
        padding: 5px;
        margin-top: 5px;
      }
      .more_ico:hover {
        color: #990915;
      }
      .retract_ico {
        transform: rotate(180deg);
      }
    }
  }
  .info_item_large {
    height: 40px;
    border-bottom: 1px solid #f0f1f2;
    .price_large {
      font-size: 20px;
    }
    .right_amt {
      display: flex;
      align-items: center;
      .save_price {
        font-size: 14px;
        line-height: 18px;
        color: #cc0c1c;
        margin-right: 8px;
      }
    }
  }
  .price_font {
    // font-family: JDZhengHT-EN;
  }
  .free_tip_style {
    color: #269636;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  }
}
