.wrap {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-top: 32px;
  .detail_title_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    .status_txt {
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
    }
  }
  .ageTips {
    height: 52px;
    background-color: #f7f8fc;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 8px;
    margin-top: 16px;
    cursor: pointer;
    .ageTipsContent {
      margin: 0 4px;
      font-weight: 700;
    }
    .entry_ico {
      margin-left: 4px;
      color: #888b94;
      width: 10px;
      transform: rotate(-90deg);
    }
  }
  .btn_group {
    display: flex;
    align-items: center;
  }
  .detail_desc_wrap {
    display: flex;
    padding: 16px;
    border-radius: 8px;
    background: rgba(247, 248, 252, 1);
    margin-top: 16px;
    // 左右结构
    .left_part {
      width: 456px;
      padding: 0 24px 0 8px;
      .delivery_title {
        display: flex;
        align-items: center;
        font-size: 16px;
        height: 36px;
        font-weight: 700;
        line-height: 20px;
        color: rgba(0, 0, 0, 1);
        i {
          width: 16px;
          height: 18px;
          background: url("../../assets/shipped_ico.png") no-repeat;
          background-size: 100%;
          margin-right: 8px;
        }
      }
      .delivery_wrap {
        height: 36px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
      }
      .copy_btn {
        color: rgba(24, 105, 245, 1);
        cursor: pointer;
        padding-left: 3px;
      }
      .errorText {
        color: #888b94;
      }
      .copy_btn:hover {
        color: #990915;
      }
      .packageContentWrap {
        border-top: 1px solid #f0f1f2;
        margin-top: 7.5px;
        padding-top: 7.5px;
      }
      .packageTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        line-height: 20px;
        height: 36px;
        cursor: pointer;
        position: relative;
        .packageTitleRight {
          display: flex;
          align-items: center;
          color: #888b94;
          .more_ico {
            color: #505259;
            width: 10px;
            margin-left: 8px;
            transition: transform 0.3s ease;
          }
        }
      }
      .packageTitle:hover {
        .more_ico {
          transform: rotate(180deg);
        }
        .allPackageWareListOuterWrap {
          display: block;
          position: absolute;
          top: 36px;
          left: -12px;
          z-index: 1;
        }
      }
      .packageWareListWrap {
        display: flex;
      }
      .productImg {
        width: 75px;
        height: 75px;
        margin-right: 12px;
      }
      .productImg:last-child {
        margin-right: 0;
      }
      .allPackageWareListOuterWrap {
        display: none;
      }
      .allPackageWareListWrap {
        background-color: #fff;
        box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        padding: 12px 0 0 12px;
        width: 447px;
        display: flex;
        flex-wrap: wrap;
        .productImg {
          margin-bottom: 12px;
        }
      }
    }
    .right_part {
      flex: 1;
      border-left: 1px solid rgba(0, 0, 0, 0.06);
      padding: 8px 24px;
      .viewAllWrap {
        height: 24px;
        margin-top: 12px;
        justify-content: center;
      }
    }
  }
  .detail_desc_special_wrap {
    background: rgba(255, 243, 244, 1);
  }
  .detail_desc_wrap_separate {
    justify-content: space-between;
  }
  .count_down_wrap {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  .timeout_cancel {
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 1);
  }
  .after_sales_wrap {
    display: flex;
    align-items: center;
    i {
      width: 16px;
      height: 18px;
      background: url("../../assets/cancel_ico.png") no-repeat;
      background-size: 100%;
      margin-right: 8px;
    }
    .after_sales_ico {
      background-image: url("../../assets/refund_ico.png");
    }
    .after_sales_title {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: rgba(0, 0, 0, 1);
      margin-right: 8px;
    }
  }
  .after_sales_detail {
    font-size: 14px;
    line-height: 18px;
    color: rgba(80, 82, 89, 1);
  }
  .copy_btn {
    color: rgba(24, 105, 245, 1);
    cursor: pointer;
    padding-left: 8px;
  }
  .copy_btn:hover {
    color: #990915;
  }
  .tip_ico {
    cursor: pointer;
    margin-left: 4px;
    width: 16px;
    height: 16px;
    padding: 1.5px;
  }

  .viewAllWrap {
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .more_ico {
      color: rgba(26, 26, 26, 1);
      width: 12px;
      margin-left: 8px;
    }
    .retract_ico {
      transform: rotate(180deg);
    }
  }
  .viewAllWrap:hover {
    color: #990915;
    .more_ico {
      color: #990915;
    }
  }
  // 一单多包裹
  .packageOuterWrap {
    position: relative;
    .packageWrap {
      display: flex;
      flex-wrap: wrap;
      .packageItem {
        display: flex;
        align-items: center;
        height: 36px;
        padding: 0 12px;
        background-color: #f7f8fc;
        border: 0.5px solid transparent;
        border-radius: 4px;
        font-size: 16px;
        margin: 8px 8px 8px 0;
        cursor: pointer;
        span {
          color: #505259;
          margin-left: 4px;
        }
      }
      .packageItem:last-child {
        // todo 让出悬浮按钮的位置
        margin-right: 0;
      }
      .activePackageItem {
        background-color: #fff3f4;
        border: 0.5px solid #cc0c1c;
      }
    }
    .viewAllWrap {
      position: absolute;
      right: 0;
      bottom: 8px;
      height: 36px;
      padding: 0 16px;
      background-color: #fff;
    }
  }

  .oneLineStyle {
    max-height: 52px;
    overflow: hidden;
    .packageWrap {
      .packageItem:last-child {
        margin-right: 0;
      }
    }
  }
}
.modalTitle {
  font-size: 16px;
  line-height: 20px;
}
.modalTitleBold {
  font-weight: 700;
}
.modalDesc {
  font-size: 14px;
  line-height: 18px;
  color: #1a1a1a;
  margin-top: 12px;
}
