.contentWrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.packageOuterWrap {
  position: relative;
  .packageWrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    .packageItem {
      display: flex;
      align-items: center;
      height: 36px;
      padding: 0 12px;
      background-color: #f7f8fc;
      border: 0.5px solid transparent;
      border-radius: 4px;
      font-size: 16px;
      margin-right: 8px;
      margin-bottom: 8px;
      cursor: pointer;
      span {
        color: #505259;
        margin-left: 4px;
      }
    }
    .packageItem:last-child {
      margin-right: 0;
    }
    .activePackageItem {
      background-color: #fff3f4;
      border: 0.5px solid #cc0c1c;
    }
  }
  .viewAllWrap {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 36px;
    padding: 0 16px;
    background-color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .more_ico {
      color: rgba(26, 26, 26, 1);
      width: 12px;
      margin-left: 8px;
    }
    .retract_ico {
      transform: rotate(180deg);
    }
  }
  .viewAllWrap:hover {
    color: #990915;
    .more_ico {
      color: #990915;
    }
  }
}
.oneLineStyle {
  max-height: 44px;
  overflow: hidden;
}
.express_content {
  padding-top: 8px;
  .express_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    .copy_btn {
      color: #1869f5;
      cursor: pointer;
      padding-left: 3px;
    }
    .copy_btn:hover {
      color: #990915;
    }
  }
  .express_item_special {
    height: auto;
    align-items: flex-start;
    margin-top: 9px;
    .addressTitleStyle {
      line-height: 18px;
    }
  }
}
.logistics_content {
  background: #f7f8fc;
  border-radius: 8px;
  margin-top: 12px;
  padding: 16px 12px;
  // max-height: 400px;
  overflow-y: auto;
  flex: 1;
}
.loading_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  .loading_text {
    font-size: 14px;
    color: #c2c4cc;
    margin-top: 14px;
  }
}
.address_item {
  line-height: 18px;
  text-align: right;
  flex: 1;
  margin-left: 10px;
  max-width: 472px;
  .address_item_inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // flex-wrap: wrap;
  }
  .address_nick_name {
    font-weight: 700;
    max-width: 330px;
    color: #1a1a1a;
    font-size: 16px;
  }
  .address_postcode {
    margin-left: 4px;
    max-width: 140px;
    color: #1a1a1a;
    font-size: 16px;
  }
  .address_detail {
    margin-top: 4px;
    color: #1a1a1a;
    text-align: right;
    word-break: break-word;
    font-size: 16px;
  }
}
.errorText {
  color: #888b94;
}
.errorWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
