.wrap {
  padding-top: 32px;

  .breadcrumbs_wrap {
    color: rgba(136, 139, 148, 1);
    font-size: 14px;

    :global {
      .MuiBreadcrumbs-li:last-child {
        font-weight: 700;
      }
    }
  }

  .item_wrap {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0%;

    &:hover {
      color: rgba(255, 15, 35, 1);
    }
  }

  .item_icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    font-weight: 100;
  }

  .separator_ico {
    width: 20px;
  }
}
