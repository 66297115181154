.order_content {
  flex: 1;
  color: rgba(26, 26, 26, 1);
  background-color: #fff;
  border-radius: 8px;
  padding: 0 16px 16px;
  .order_title_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    height: 72px;
    .order_title {
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
    }
  }
}
