@import "@/app/csr-src/pages/order/common/common.scss";

.detail_left_wrap {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 8px 16px;

  .refund_item_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .refund_item_title {
    height: 32px;
    margin-bottom: 8px;
  }

  .refund_item_common_title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: var(--sys-Text-Primary);
  }

  .tip_ico {
    cursor: pointer;
  }

  .refund_item_sub_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 700;
    height: 32px;

    .price_wrap {
      font-family: JDZhengHT-EN;
      font-size: 18px;
      color: #cc0c1c;
      line-height: 24px;
      font-weight: normal;
    }
  }

  .refund_item_desc_wrap {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
  }

  .refund_item_divide {
    margin: 8px 0;
  }

  .coupon_desc {
    font-size: 14px;
    color: #888b94;
    line-height: 18px;
    margin: 8px 0;
  }
}