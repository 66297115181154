.afterSalesTrajectory_wrap {
  padding: 8px 16px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;

  .trajectoryItem {
    padding: 8px 0;
    border-bottom: 1px solid var(--sys-Surface-Outline200);

    &:last-of-type {
      border-bottom: none;
    }
  }
}

.title {
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .titleText {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--sys-Text-Primary);
    transition: font-weight 0.3s linear, font-size 0.3s linear, line-height 0.3s linear;
    /* 添加过渡效果 */

    &.expanded {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }

    .competedIcon {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }

    .activeIcon {
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: var(--sys-color-Primary700);
      color: #fff;
      text-align: center;
      line-height: 18px;
      font-weight: 400;
      font-size: 10.5px;
      margin-right: 8px;
    }
  }

  .date {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0%;
    vertical-align: middle;
    color: var(--sys-Text-Tertiary);
  }
}

.content {
  padding-left: 26px;
  padding-top: 8px;
  font-weight: 400;
  font-size: 14px;
}

.returnDropOff {
  display: flex;
  margin: 8px 0px 8px 26px;
  border-top: 1px solid var(--sys-Surface-Outline200);
  gap: 24px;

  .left {
    width: 50%;
    background-color: var(--sys-background-CardGray);
    border-radius: 8px;
    padding: 8px 16px;
    margin-top: 8px;

    .leftTitle {
      height: 20px;
      font-weight: 700;
      font-size: 14px;
      color: var(--sys-Text-Primary);

      .changeBtn {
        display: flex;
        align-items: center;
        float: right;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: var(--sys-Text-Tertiary);
        cursor: pointer;

        &:hover {
          color: var(--sys-color-Primary700);

          .entryIco {
            color: var(--sys-color-Primary700);
          }
        }
      }

      .entryIco {
        color: var(--sys-Text-Tertiary);
        margin-left: 4px;
        width: 16px;
        height: 16px;
        transform: rotate(-90deg);
        position: relative;
        top: 2px;
      }
    }

    .leftAdd {
      display: flex;
      align-items: center;
      padding: 3px 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: var(--sys-Text-Primary);
    }

    .openingTime {
      font-weight: 400;
      font-size: 14px;
      color: var(--sys-Text-Tertiary);

      .openingTimeLi {
        height: 26px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .right {
    width: 50%;
    display: flex;
    gap: 29px;
    align-items: center;

    .qrImage {
      width: 160px;
      height: 160px;
      cursor: pointer;
    }

    .qrTips {
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex: 1;

      .qrText {
        font-weight: 400;
        font-size: 14px;
        color: var(--sys-Text-Primary);
      }

      .qrDownload {
        align-self: flex-end;
        height: 32px;
        border: 1px solid var(--sys-Surface-Outline300);
        border-radius: 4px;
        padding: 0 12px;
        line-height: 32px;
        font-weight: 400;
        font-size: 14px;
        color: var(--sys-Text-Primary);
        cursor: pointer;

        &:hover {
          color: var(--sys-color-Primary700);
          border-color: var(--sys-color-Primary700);
        }
      }
    }
  }
}

.returnPickUp {
  display: flex;
  gap: 48px;
  margin: 8px 0px 8px 26px;
  padding: 8px 0;

  .pickUpTime {
    width: 50%;

    .pickUpTimeTitle {
      font-weight: 700;
      font-size: 14px;
      color: var(--sys-Text-Primary);
    }

    .pickUpTimeContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 4px;
      background-color: var(--sys-Surface-Surface100);
      padding: 13px 12px;
      margin-top: 8px;

      .pickUpTimeContentBtns {
        display: flex;

        .pickUpTimeContentBtn {
          height: 32px;
          line-height: 32px;
          font-weight: 400;
          font-size: 14px;
          padding: 0 12px;
          text-align: center;
          border: 1px solid var(--sys-Surface-Outline300);
          border-radius: 4px;
          cursor: pointer;

          &:hover {
            color: var(--sys-color-Primary700);
            border-color: var(--sys-color-Primary700);
          }
        }

        .save {
          margin-left: 8px;
          color: var(--sys-color-Primary700);
          border-color: var(--sys-color-Primary700);
        }
      }
    }
  }

  .pickUpAddress {
    width: 50%;

    .pickUpAddressTitle {
      font-weight: 700;
      font-size: 14px;
      color: var(--sys-Text-Primary);
    }

    .pickUpAddressContent {
      margin-top: 8px;

      .address {
        .addressName {
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          color: var(--sys-Text-Primary);
        }

        .phone {
          display: inline-block;
          margin-left: 8px;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: var(--sys-Text-Primary);
        }
      }

      .addressDetails {
        margin-top: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: var(--sys-Text-Secondary);
      }
    }
  }
}
