.afs_detail_wrap {
  width: 1264px;
  margin: 0 auto;
  .detail_inner_wrap {
    background-color: rgba(245, 246, 250, 1);
    color: rgba(26, 26, 26, 1);
    font-size: 14px;
    padding-bottom: 32px;
    .step_outer_wrap {
      width: 872px;
      margin: 32px auto;
    }
    .step_outer_wrap2 {
      width: 700px;
    }

    .step_outer_wrap3 {
      width: 540px;
    }
  }
}
.afterSalesAccordion_wrap{
  display: flex;
  gap: 16px;
  margin-top: 16px;
 } 
