@import "@/app/csr-src/pages/order/common/common.scss";
.select_wrap {
  background-color: #f7f8fc;
  border-radius: 4px;
  .placeholder_style {
    color: #c2c4cc;
  }
  :global {
    .MuiSelect-outlined.MuiSelect-select {
      padding: 9.5px 12px;
      color: #1a1a1a;
      font-size: 14px;
      // min-height: 23px;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
    .MuiFormControl-root {
      .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: transparent;
        }
      }
      .MuiOutlinedInput-root:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: transparent;
        }
      }
    }
    .MuiSelect-iconOutlined {
      top: calc(50% - 10px);
    }
  }
}
.address_main {
  display: flex;
  align-items: center;
  color: #1a1a1a;
  .address_name {
    font-weight: 700;
    @include txtSingleR;
    margin-right: 8px;
    // flex: 1 1 auto;
  }
  .address_phone {
    // flex: 0 0 auto;
    white-space: nowrap;
  }
}
.address_detail {
  color: #505259;
  margin-top: 4px;
  white-space: pre-line;
  line-height: 18px;
  @include txtline(1);
}
.address_wrap {
  .address_main {
    width: 470px;
    .address_name {
      margin-right: 16px;
    }
  }
  .address_detail {
    width: 470px;
    @include txtline(2);
  }
}
.add_address_wrap {
  // position: sticky;
  // bottom: 0;
  // left: 0;
  // background-color: #fff;
  width: 100%;
  color: #cc0c1c;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .add_icon {
    margin-right: 6px;
  }
}
