@import "../../common/common.scss";
.ruleWrap {
  height: 36px;
  display: flex;
  align-items: center;
  background: #eaf5fd;
  padding: 0 8px;
  color: #207fbb;
  font-size: 14px;
  cursor: pointer;
  .tipIco {
    // width: 16px;
    // height: 16px;
    margin-right: 4px;
  }
  .ruleContent {
    flex: 1;
    @include txtSingleR;
  }
  .entryIco {
    color: #207fbb;
    margin-left: 4px;
    width: 18px;
    height: 18px;
    transform: rotate(-90deg);
  }
}
.reason_wrap {
  position: relative;
  margin-top: 8px;
}